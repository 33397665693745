<template>
    <div class="documentos">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    metaInfo: {
        title: 'Documentos'
    },
}
</script>

<style>

</style>